<template>
	<div id="rootVM">
		<div id="pageTitle">
			<b>현장별 사용자 조회</b>
		</div>
		<div>
			<div id="treeViewExample">
				<div class="demo-section wide k-content">
					<div class="treeview-flex">
						<div id="treeview-kendo"></div>
					</div>
				</div>
			</div>
			<div class="content">
				<!-- 해당 현장의 안전관리자 그리드 -->
				<div>
					<h2>{{ curSiteNm }} 안전관리자</h2>
					<KendoGrid
						ref="supervisorGrid"
						:auto-bind="true"
						:api-url="treeUrl.inqSiteSafetySupervisorInfo"
						:columns="siteSafetySupervisorGridColumns"
						:apply-search-condition="applySearchStateOnGridLoadForGettingSupervisorInfo"
						:height="250"
					></KendoGrid>
				</div>
				<!-- 해당 현장 혹은 업체의 사용자 그리드 -->
				<div>
					<h2 v-if="isCompany">{{ curCompanyNm }} 사용자 리스트</h2>
					<h2 v-if="isSite">{{ curSiteNm }} 사용자 리스트</h2>
					<KendoGrid
						ref="userGrid"
						:auto-bind="true"
						:api-url="treeUrl.inqUserInfo"
						:columns="userGridColumns"
						:apply-search-condition="applySearchStateOnGridLoad"
					></KendoGrid>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import apiIndex from '../../api/index';
import KendoGrid from '@/components/KendoGrid';

let axiosExtention;

export default {
	components: {
		KendoGrid: KendoGrid,
	},
	data: () => ({
		pageParam: '',
		siteMap: {},
		siteAndCompanyList: [],
		dataSource: [], //트리에 세팅할 데이터 소스
		// 안전관리자 그리드 컬럼
		siteSafetySupervisorGridColumns: [
			{ field: 'companyNm', title: '업체명', width: '10%' },
			{ field: 'userId', title: '사용자ID', width: '10%' },
			{ field: 'userNm', title: '성명', width: '10%' },
			{ field: 'hpNo', title: '휴대폰번호', dataFormat: 'hyphenPhone', width: '15%' },
		],
		// 사용자 그리드 컬럼
		userGridColumns: [
			{ field: 'userId', title: '사용자ID', width: '8%' },
			{ field: 'userNm', title: '사용자명', width: '10%' },
			{ field: 'siteNm', title: '현장명', width: '15%' },
			{ field: 'companyNm', title: '업체명', width: '15%' },
			{ field: 'userRoleCd', title: '역할', width: '10%', dataFormat: 'optionMap' },
			{ field: 'hpNo', title: '연락처', width: '10%', dataFormat: 'hyphenPhone', align: 'center' },
			{ field: 'delYn', title: '사용여부', width: '7%', align: 'center', dataFormat: 'optionMap' },
			{ field: 'regDtm', title: '등록일', width: '10%', align: 'center', dataFormat: 'YYYY-MM-DD' },
			{ field: 'updDtm', title: '수정일', width: '10%', align: 'center', dataFormat: 'YYYY-MM-DD' },
		],
		searchOption: { curCompanyId: '', curSiteId: '' }, //사용자 그리드의 조건 옵션
		searchOptionForGettingSuperVisor: { curSiteIdForGettingSupervisor: '' }, // 안전관리자 그리드의 조건 옵션
		treeUrl: { inqUserInfo: apiIndex.tree.inqUserInfo, inqSiteSafetySupervisorInfo: apiIndex.tree.inqSiteSafetySupervisorInfo },
		isSite: true,
		isCompany: false,
		//현재 클릭한 현장명
		curSiteNm: '',
		//현재 클릭한 업체명
		curCompanyNm: '',
		isSiteDisabled: false,
		userSiteId: '',
		search: {},
	}),
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('treePageParam'));
		if (this.pageParam.loginUserRolecd !== '00') {
			this.isSiteDisabled = true;
		}

		this.userSiteId = this.pageParam.loginUserSiteId;
		this.searchOption.curSiteId = this.userSiteId;
		this.searchOptionForGettingSuperVisor.curSiteIdForGettingSupervisor = this.userSiteId;

		axiosExtention = this.$jarvisExtention.axiosExtention;

		const commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));

		this.userGridColumns.filter(item => item.field == 'userRoleCd')[0].mapData = commonCodeList.filter(item => item.grpCd == 'user_role_cd');
		this.userGridColumns.filter(item => item.field == 'delYn')[0].mapData = commonCodeList.filter(item => item.grpCd == 'del_yn');
	},
	mounted() {
		this.getTreeData();
		// this.drawTreeView();
	},
	methods: {
		//그리드로드시 검색조건적용
		applySearchStateOnGridLoad(param) {
			return $.extend(true /*deep*/, param, this.searchOption || {});
		},
		applySearchStateOnGridLoadForGettingSupervisorInfo(param) {
			return $.extend(true /*deep*/, param, this.searchOptionForGettingSuperVisor || {});
		},
		async getTreeData() {
			const closure = this;
			this.siteAndCompanyList = [];
			if (this.isSiteDisabled) {
				this.$set(this.search, 'siteId', this.userSiteId);
			}
			await this.$axios
				.post(apiIndex.tree.inqSiteInfoAndCompanyId, this.search)
				.then(async function (r) {
					let lengthOfData = r.data.length;
					for (let i = 0; i < lengthOfData; i++) {
						closure.$set(closure.siteMap, r.data[i].siteNm, r.data[i].siteId);
						if (r.data[i].siteId == closure.userSiteId) {
							closure.curSiteNm = r.data[i].siteNm; //현재 현장명 세팅
						}
						closure.siteAndCompanyList.push({
							siteNm: r.data[i].siteNm,
							siteId: r.data[i].siteId,
							companyNm: r.data[i].companyNm,
							companyId: r.data[i].companyId,
						});
					}
				})
				// .bind(this)
				.catch(axiosExtention.buildErrorHandler());
			this.setDataSource();
		},
		setDataSource() {
			// 혹시 있을 데이터 초기화
			this.dataSource = [];
			let id = 0;
			for (var siteElement in this.siteMap) {
				var site = {};
				site.id = id++;
				site.text = siteElement;
				site.expanded = true;
				site.spriteCssClass = 'site';
				site.key = this.siteMap[siteElement];

				let companys = this.siteAndCompanyList.filter(item => item.siteNm == siteElement && item.companyId != null);
				let lengthOfCompanys = companys.length;
				var siteItems = [];
				for (let i = 0; i < lengthOfCompanys; i++) {
					var siteItem = {};
					siteItem.id = id++;
					siteItem.text = companys[i].companyNm;
					siteItem.expanded = false;
					siteItem.spriteCssClass = 'company';
					siteItem.key = companys[i].companyId;
					siteItem.parentSiteId = this.siteMap[siteElement];
					siteItem.parentSiteNm = siteElement;
					siteItems.push(siteItem);
				}
				site.items = siteItems;
				this.dataSource.push(site);
			}
			this.drawTreeView();
		},
		drawTreeView() {
			$('#treeview-kendo').kendoTreeView({
				dataSource: this.dataSource,
				// checkboxes: {
				// 	checkChildren: true,
				// },
				loadOnDemand: true,
				select: this.treeSelect,
			});
		},
		treeSelect(e) {
			let data = $('#treeview-kendo').data('kendoTreeView').dataItem(e.node);
			this.resetSearchOption();
			if (data.spriteCssClass == 'company') {
				// 현재 누른 것은 업체
				this.isSite = false;
				this.isCompany = true;
				// 사용자 데이터 조회를 위한 조건 설정
				this.searchOption.curSiteId = data.parentSiteId;
				this.searchOption.curCompanyId = data.key;
				// 화면에 관련 정보를 띄워주기 위한 데이터 설정
				this.curCompanyNm = data.text;
				this.curSiteNm = data.parentSiteNm;
				// 안전관리자 조회를 위한 조건 설정
				this.searchOptionForGettingSuperVisor.curSiteIdForGettingSupervisor = data.parentSiteId;
				// 그리드 로드
				this.$refs.userGrid.load();
				this.$refs.supervisorGrid.load();
			} else {
				// 현재 누른 것은 현장
				this.isSite = true;
				this.isCompany = false;
				// 사용자 데이터 조회를 위한 조건 설정 - 업체아이디를 검색조건에서 뺌
				this.searchOption.curCompanyId = null;
				this.searchOption.curSiteId = data.key;
				// 화면에 관련 정보를 띄워주기 위한 데이터 설정
				this.curSiteNm = data.text;
				// 안전관리자 조회를 위한 조건 설정
				this.searchOptionForGettingSuperVisor.curSiteIdForGettingSupervisor = data.key;
				// 그리드 로드
				this.$refs.userGrid.load();
				this.$refs.supervisorGrid.load();
			}
		},
		resetSearchOption() {
			this.searchOption = { curCompanyId: '', curSiteId: '' };
		},
	},
};
</script>

<style>
#rootVM > div > div {
	flex: 1;
	border: 1px solid #ddd;
	display: inline-flex;
	flex-direction: column;
}

#rootVM > div > .content {
	width: 79%;
	height: 890px;
	padding: 10px;
}

#treeViewExample {
	width: 20%;
	height: 890px;
	margin-right: 1%;
	font-size: 15px;
	font-weight: bold;
	line-height: 15px;
}

.contentRow {
	display: inline-block;
}

@media screen and (max-width: 680px) {
	.treeview-flex {
		flex: auto !important;
		width: 100%;
	}
}

#demo-section-title h3 {
	margin-bottom: 2em;
	text-align: center;
}

.treeview-flex h4 {
	color: #656565;
	margin-bottom: 1em;
	text-align: center;
}

#demo-section-title {
	width: 100%;
	flex: auto;
}

.treeview-flex {
	flex: 1;
	-ms-flex: 1 0 auto;
}

/* #treeview-kendo {
	overflow-x: hidden;
	overflow-y: auto;
	white-space: nowrap;
	text-overflow: ellipsis;
} */

.k-treeview {
	max-width: 240px;
	margin: 0 auto;
}

.demo-section {
	margin-bottom: 5px;
	overflow: auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.user {
	background-size: 100%;
	background-image: url('/images/icon-user.png');
}

.site {
	background-size: 100%;
	background-image: url('/images/icon-site.png');
}

.company {
	background-size: 100%;
	background-image: url('/images/icon-build.png');
}
</style>
